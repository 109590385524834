import { useEffect, useMemo, FormEvent, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { Spinner, Col, Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/fi";
import "dayjs/locale/sv";
import dayjs from "dayjs";
import {
  IReceiptImage,
  setReceiptFormField,
} from "../../store/slices/receiptSlice";
import { numberWithThousandSeparator } from "../../utils/common";
import { NBSP } from "../../types/constants";
import {
  PaymentMethod,
  UpdateElectricReceiptCommand,
} from "../../generated/tsClient";
import { OptionsType } from "../../types/OptionsType";
import { ModalStatus } from "../../types/ModalStatusEnum";
import ImagePreviewBackdrop from "./ImagePreviewBackdrop";
import WakkaCurrencyInput from "./wakkaCurrencyInput/WakkaCurrencyInput";
import { CurrencyInputOnChangeValues } from "./wakkaCurrencyInput/CurrencyInputProps";

interface Props {
  onSave: (event: FormEvent<HTMLFormElement>) => void;
  onClose: () => void;
  setPriceStr: (priceStr: string) => void;
  priceStr: string;
  receiptImage: IReceiptImage | null;
}
const ReceiptForm = ({
  onSave,
  onClose,
  setPriceStr,
  priceStr,
  receiptImage,
}: Props) => {
  const { t } = useTranslation(["texts", "errors"]);
  const dispatch = useAppDispatch();
  const selectedBookkeeping = useAppSelector(
    (state) => state.bookkeeping.bookkeeping
  );
  const selectedAccountingPeriod = useAppSelector(
    (state) => state.accountingPeriods.selectedPeriod
  );
  const modalStatus = useAppSelector((state) => state.receipts.modalStatus);
  const receiptForm = useAppSelector((state) => state.receipts.receiptForm);
  const receiptFile = useAppSelector((state) => state.receipts.receiptFile);
  const isLoading = useAppSelector((state) => state.receipts.isLoading);
  const disabled = useMemo(() => {
    return isLoading || modalStatus === ModalStatus.Open;
  }, [modalStatus, isLoading]);

  const electricReceiptId = (receiptForm as UpdateElectricReceiptCommand)
    .electricReceiptId;

  const paymentMethodOptions: OptionsType[] = useMemo(() => {
    return [
      { label: NBSP, value: 0 },
      {
        label: t("SAVERECEIPT_PAYMENT_METHOD_CARD"),
        value: PaymentMethod.CARD,
      },
      {
        label: t("SAVERECEIPT_PAYMENT_METHOD_CASH"),
        value: PaymentMethod.CASH,
      },
      {
        label: t("SAVERECEIPT_PAYMENT_METHOD_BANK_TRANSFER"),
        value: PaymentMethod.BANK_TRANSFER,
      },
    ];
  }, [t]);

  const formatPrice = () => {
    setPriceStr(receiptForm?.price?.toFixed(2) ?? "0");
  };

  useEffect(() => {
    if (electricReceiptId) {
      formatPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [electricReceiptId]);

  return (
    <Box component="form" onSubmit={onSave} noValidate={true}>
      <Row>
        <Col className="d-flex justify-content-between">
          <div>{selectedBookkeeping?.description}</div>
          <div>{selectedAccountingPeriod?.name}</div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <InputLabel shrink={false} required id="label.saveReceipt.date" htmlFor="textField.saveReceipt.date">
            {t("LABEL_DATE")}
          </InputLabel>
          <DatePicker
            format="DD.MM.YYYY"
            value={dayjs(receiptForm.paymentDate) ?? ""}
            onChange={(paymentDate) =>
              dispatch(
                setReceiptFormField({
                  paymentDate: dayjs(paymentDate).toDate() ?? "",
                })
              )
            }
            disabled={disabled}
            slotProps={{
              textField: {
                id: "textField.saveReceipt.date",
                size: "small",
                variant: "outlined",
                required: true,
                InputLabelProps: { shrink: true },
                sx: {
                  marginTop: "5px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#99C2FF",
                    borderWidth: "2px",
                  },
                  "& .MuiOutlinedInput-notchedOutline legend": {
                    display: "none",
                  },
                },
              },
            }}
          />
        </Col>
        <Col>
          <InputLabel shrink={false} id="label.saveReceipt.paymentMethod" htmlFor="select.saveReceipt.paymentMethod">
            {t("SAVERECEIPT_PAYMENT_METHOD")}
          </InputLabel>
          <Select
            label="label.saveReceipt.paymentMethod"
            inputProps={{ id: "select.saveReceipt.paymentMethod" }}
            value={receiptForm.paymentMethod?.toString() ?? ""}
            defaultValue="0"
            onChange={(event) =>
              dispatch(
                setReceiptFormField({
                  paymentMethod:
                    event.target.value !== "0"
                      ? (event.target.value as unknown as PaymentMethod)
                      : undefined,
                })
              )
            }
            fullWidth={true}
            notched={false}
            disabled={disabled}
            sx={{
              height: "42px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#99C2FF",
                borderWidth: "2px",
              },
            }}
          >
            {paymentMethodOptions.map((paymentMethodOption) => (
              <MenuItem
                key={paymentMethodOption.value}
                value={paymentMethodOption.value}
              >
                {paymentMethodOption.label}
              </MenuItem>
            ))}
          </Select>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <InputLabel shrink={false} id="label.saveReceipt.description" htmlFor="textField.saveReceipt.description">
            {t("SAVERECEIPT_DESCRIPTION")}
          </InputLabel>
          <TextField
            id="textField.saveReceipt.description"
            size="small"
            variant="outlined"
            autoComplete="off"
            disabled={disabled}
            fullWidth={true}
            inputProps={{ maxLength: 105 }}    
            value={receiptForm.description}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) =>
              dispatch(setReceiptFormField({ description: event.target.value }))
            }
            sx={{
              marginTop: "5px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#99C2FF",
                borderWidth: "2px",
              },
              "& .MuiOutlinedInput-notchedOutline legend": {
                display: "none",
              },
            }}
            InputLabelProps={{
              shrink: false,
            }}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <InputLabel shrink={false} id="label.saveReceipt.purchasePlace" htmlFor="textField.saveReceipt.purchasePlace">
            {t("SAVERECEIPT_PURCHASE_PLACE")}
          </InputLabel>
          <TextField
            id="textField.saveReceipt.purchasePlace"
            size="small"
            variant="outlined"
            autoComplete="off"
            disabled={disabled}
            fullWidth={true}
            inputProps={{ maxLength: 30 }}
            value={receiptForm.purchasePlace}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) =>
              dispatch(
                setReceiptFormField({ purchasePlace: event.target.value })
              )
            }
            sx={{
              marginTop: "5px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#99C2FF",
                borderWidth: "2px",
              },
              "& .MuiOutlinedInput-notchedOutline legend": {
                display: "none",
              },
            }}
            InputLabelProps={{
              shrink: false,
            }}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <InputLabel shrink={false} id="label.saveReceipt.price" required htmlFor="textField.saveReceipt.price">
            {t("LABEL_PRICE")}
          </InputLabel>
          <WakkaCurrencyInput
            id="textField.saveReceipt.price"
            value={priceStr ?? ""}
            onValueChange={(
              value: string | undefined,
              name?: string | undefined,
              values?: CurrencyInputOnChangeValues | undefined
            ) => {
              setPriceStr(value ?? "");
              dispatch(
                setReceiptFormField({
                  price: values?.float ?? 0,
                })
              );
            }}
            disabled={disabled}
            maxLength={18}
            decimalScale={2}
            decimalSeparator=","
            groupSeparator=" "
            allowNegativeValue={false}
            sx={{
              width: "50%",
              marginTop: "5px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#99C2FF",
                borderWidth: "2px",
              },
              "& .MuiOutlinedInput-notchedOutline legend": {
                display: "none",
              },
            }}
            inputLabelProps={{
              shrink: false,
            }}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          {isLoading && <Spinner color="light" />}
          {!isLoading && receiptFile && (
            <>
              {receiptFile.contentType === "application/pdf" ? (
                <a href={receiptFile.url} download>
                  {receiptFile.fileName ?? t("OPEN_RECEIPT")}
                </a>
              ) : (
                <ImagePreviewBackdrop imageURL={receiptFile.url} />
              )}
            </>
          )}

          {receiptImage?.imagePreview && (
            <ImagePreviewBackdrop imageURL={receiptImage.imagePreview} />
          )}
          {!receiptImage?.imagePreview && <p>{receiptImage?.imageFileName}</p>}
        </Col>
      </Row>
      <Row className="borderTop mt-4">
        <Col className="d-flex flex-row-reverse mt-3">
          <div>
            <Button
              variant="contained"
              size="medium"
              onClick={onClose}
              style={{
                width: "90px",
              }}
            >
              {isLoading ? (
                <Spinner
                  style={{ width: "1.5rem", height: "1.5rem" }}
                  color="light"
                />
              ) : (
                t("CANCEL_BUTTON")
              )}
            </Button>
            {modalStatus !== ModalStatus.Open && (
              <Button
                variant="contained"
                size="medium"
                type="submit"
                disabled={!receiptForm.paymentDate || disabled}
                style={{
                  width: "90px",
                  marginLeft: "5px",
                }}
              >
                {isLoading ? (
                  <Spinner
                    style={{ width: "1.5rem", height: "1.5rem" }}
                    color="light"
                  />
                ) : (
                  t("SAVE_BUTTON")
                )}
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </Box>
  );
};

export default ReceiptForm;
