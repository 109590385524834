import { combineReducers, configureStore, Tuple } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { thunk } from 'redux-thunk';

import appReducer from './slices/appSlice';
import loginReducer from './slices/loginSlice';
import bookkeepingReducer from './slices/bookkeepingSlice';
import bookkeepingSettingsReducer from './slices/bookkeepingSettingsSlice';
import accountingPeriodsReducer from './slices/accountingPeriodsSlice';
import receiptReducer from './slices/receiptSlice';

const persistConfig = {
  key: "mobiwakka_root",
  storage,
  blacklist: ['receipts']
};

const peristConfigReceipts = {
  key: 'receipt',
  storage,
  blacklist: ['modalStatus']
};

const rootReducer = combineReducers({
  app: appReducer,
  login: loginReducer,
  bookkeeping: bookkeepingReducer,
  bookkeepingSettings: bookkeepingSettingsReducer,
  accountingPeriods: accountingPeriodsReducer,
  receipts: persistReducer(peristConfigReceipts, receiptReducer)
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: () => new Tuple(thunk)
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
