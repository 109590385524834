import React, { ChangeEvent, Fragment } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  ReceivedElectricReceiptDto,
  UpdateElectricReceiptCommand,
} from "../../generated/tsClient";
import "dayjs/locale/fi";
import "dayjs/locale/sv";
import {
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  formatDateToString,
  formatNumberWithCommaDecimalSeparator,
} from "../../utils/common";
import {
  setModalStatus,
  setReceiptFormField,
  setIsLoading,
} from "../../store/slices/receiptSlice";
import { ModalStatus } from "../../types/ModalStatusEnum";
import { fetchReceiptElectricReceiptFile } from "../../services/receiptService";
interface Props {
  receiptsStatusIsNotNew?: boolean;
  receipts: ReceivedElectricReceiptDto[];
  selectedReceipts: number[];
  handleToggle?: (event: ChangeEvent<HTMLInputElement>) => void;
}
const CommonReceiptsList = ({
  receiptsStatusIsNotNew,
  receipts,
  selectedReceipts,
  handleToggle,
}: Props) => {
  const selectedBookkeeping = useAppSelector(
    (state) => state.bookkeeping.bookkeeping
  );
  const selectedAccountingPeriod = useAppSelector(
    (state) => state.accountingPeriods.selectedPeriod
  );
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.receipts.isLoading);
  const onChevronClick = (receipt: ReceivedElectricReceiptDto) => {
    if (
      !selectedBookkeeping?.id ||
      !selectedAccountingPeriod?.periodId ||
      !receipt.electricReceiptId
    ) {
      return;
    }

    dispatch(setIsLoading(true));
    fetchReceiptElectricReceiptFile(
      selectedBookkeeping?.id?.toString(),
      receipt.electricReceiptId?.toString()
    ).finally(() => dispatch(setIsLoading(false)));

    dispatch(
      setModalStatus(
        receiptsStatusIsNotNew ? ModalStatus.Open : ModalStatus.Edit
      )
    );
    dispatch(
      setReceiptFormField({
        ...(receipt as UpdateElectricReceiptCommand),
        price: receipt.prices?.[0].price,
        electricReceiptPriceId: receipt.prices?.[0].electricReceiptPriceId,
      })
    );
  };

  return (
    <>
      <Divider variant="fullWidth" component="div" />
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {receipts?.length > 0 &&
          receipts.map((receipt) => (
            <Fragment key={receipt.electricReceiptId}>
              <ListItem
                disableGutters
                alignItems="flex-start"
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="arrow forward"
                    onClick={() => onChevronClick(receipt)}
                    disabled={isLoading}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                }
                sx={{
                  marginLeft: "auto",
                  backgroundColor: selectedReceipts?.includes(
                    // Use this for the selected item. This is because the "selected" prop is deprecated.
                    receipt?.electricReceiptId ?? 0
                  )
                    ? "#D8EDF7"
                    : "",
                }}
                divider
              >
                {!receiptsStatusIsNotNew && (
                  <Checkbox
                    id={'receipt-checkbox-' + receipt?.electricReceiptId}
                    value={receipt?.electricReceiptId ?? ""}
                    icon={
                      <StickyNote2OutlinedIcon
                        fontSize="large"
                      />
                    }
                    checkedIcon={
                      <StickyNote2OutlinedIcon
                        color="primary"
                        fontSize="large"
                      />
                    }
                    onChange={(event) => handleToggle?.(event)}
                    checked={selectedReceipts?.includes(
                      receipt?.electricReceiptId ?? 0
                    )}
                  />
                )}

                <ListItemText
                  sx={{ marginLeft: 2 }}
                  primary={formatDateToString(receipt.paymentDate)}
                  secondary={receipt.description}
                />
                <ListItemText
                  primary={formatNumberWithCommaDecimalSeparator(
                    receipt?.prices?.[0]?.price ?? 0
                  )}
                  sx={{
                    marginTop: "6px",
                    marginBottom: "6px",
                    flex: "",
                    justifyContent: "flex-end",
                    display: "flex",
                  }}
                />
              </ListItem>
            </Fragment>
          ))}
      </List>
    </>
  );
};

export default CommonReceiptsList;
